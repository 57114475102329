<template>
  <div class="group-detail">
    <div class="page-left">
      <div class="top">
        <div class="user-photo">
          <img
            :src="
              info.channelPhotoBase64
                ? 'data:image/png;base64,' + info.channelPhotoBase64
                : require('@/assets/imgs/p1.png')
            "
            alt=""
            :onerror="noPic"
          />
          <div>
            <div class="name">{{ info.channelTitle }}</div>
            <div class="id">ID:{{ info.channelId }}</div>
          </div>
        </div>
        <div class="more">
          <el-popover
            placement="bottom"
            width="150"
            trigger="click"
            popper-class="group-page-popover"
          >
            <div class="group-detail-popover-box">
              <!-- <div class="more-item">
                <img :src="require('@/assets/imgs/i6.png')" alt="" />
                <span>群分析</span>
              </div> -->
              <div class="more-item" @click="markFun">
                <img :src="require('@/assets/imgs/i7.png')" alt="" />
                <span>打标记</span>
              </div>
              <div class="more-item" @click="exportFile(info.channelId)">
                <img :src="require('@/assets/imgs/i8.png')" alt="" />
                <span>导 出</span>
              </div>
              <div class="more-item" @click="exportMember(info.channelId)">
                <img :src="require('@/assets/imgs/i8.png')" alt="" />
                <span>导出群成员</span>
              </div>
              <div
                v-if="userInfo.isYs == 0"
                class="more-item"
                @click="exportAddress(info.channelId)"
              >
                <img :src="require('@/assets/imgs/i10.png')" alt="" />
                <span>归属地导出</span>
              </div>
              <div class="more-item" @click="exportImg">
                <img :src="require('@/assets/imgs/i16.png')" alt="" />
                <span>导出群聊图片</span>
              </div>
            </div>
            <img
              slot="reference"
              :src="require('@/assets/imgs/i5.png')"
              alt=""
            />
          </el-popover>
        </div>
      </div>
      <div class="content">
        <div>
          <label>群链接：</label>
          <div>
            <a
              style="color: blue;"
              v-if="info.channelUsername"
              :href="'https://t.me/' + info.channelUsername"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ 'https://t.me/' + info.channelUsername }}
            </a>
            <a
              v-else
              style="color: blue;"
              :href="'https://t.me/c/' + info.channelId"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ 'https://t.me/c/' + info.channelId }}
            </a>
          </div>
        </div>
        <div>
          <label>账号：</label>
          <div>{{ info.channelUsername }}</div>
        </div>
        <div>
          <label>简介：</label>
          <div :title="info.channelSummary" class="memo">
            <span v-if="info.channelSummary">
              {{ info.channelSummary }}
            </span>
            <span v-else>暂无</span>
          </div>
        </div>
        <div>
          <label>标记：</label>
          <div class="mark-box" v-if="info.tipChannelList.length > 0">
            <el-tag
              style="margin:0 0 5px 5px"
              v-for="tag in info.tipChannelList"
              :key="tag.name"
              closable
              type="danger"
              @close="cloesTag(tag.tipId)"
              size="mini"
            >
              {{ tag.tipName }}
            </el-tag>
          </div>
          <div class="mark-box" v-else>暂无</div>
        </div>
      </div>
      <div class="member">
        <span>成员</span>
        (
        <span>{{ userTotal }}</span>
        <span class="update-member" @click="updateMember">成员更新</span>
        )
        <a v-if="userInfo.isYs == 1" @click="openPlace(info.channelId)">
          <!-- <img :src="require('@/assets/imgs/group.jpg')" /> -->
          群成员归属地
        </a>
      </div>
      <div class="select-box">
        <el-select
          v-model="select"
          size="small"
          style="width:100%"
          placeholder=""
          @change="selectFun"
          :disabled="parseInt(userTotal) == 0"
        >
          <el-option label="群管理成员" :value="4"></el-option>
          <el-option label="全部群成员" :value="0"></el-option>
          <el-option label="近1个月发言热度排序" :value="1"></el-option>
          <el-option label="近半年发言热度排序" :value="2"></el-option>
          <el-option label="近一年发言热度排序" :value="3"></el-option>
        </el-select>
      </div>
      <div class="group-list" v-loading="userLoadig">
        <div
          id="t-scroll-groupUser"
          class="group-list-child"
          :infinite-scroll-disabled="userScrollDisabled"
          v-infinite-scroll="getuserList"
          :infinite-scroll-distance="10"
        >
          <ul>
            <li
              v-for="(item, i) in userArr"
              :key="i"
              :class="selectUserId == item.userId ? 'select-user' : ''"
            >
              <div class="left">
                <span>
                  <img
                    class="left-img"
                    slot="reference"
                    :src="
                      item.user_photo
                        ? 'data:image/png;base64,' + item.user_photo
                        : require('@/assets/imgs/p.png')
                    "
                    alt=""
                  />
                </span>
                <div>
                  <div>{{ item.userFirstName }} {{ item.userLastName }}</div>
                  <div>{{ item.userId }}</div>
                </div>
              </div>
              <div class="right">
                <div class="right-1" v-if="select == 4">
                  <div v-if="item.participant">
                    {{ item.participant == 1 ? '创建者' : '管理员' }}
                  </div>
                </div>
                <div class="right-1" v-else>
                  <img :src="require('@/assets/imgs/fire.png')" alt="" />
                  <div>{{ item.hot }}</div>
                </div>
                <div class="right-2">
                  <el-popover
                    placement="right"
                    trigger="hover"
                    popper-class="group-page-popover"
                  >
                    <div class="group-detail-popover-box">
                      <div
                        class="user-item"
                        @click="userDetailFun(item.userId)"
                      >
                        成员信息
                      </div>
                      <div class="user-item" @click="userMessage(item.userId)">
                        成员发言
                      </div>
                    </div>
                    <img
                      class="more-img"
                      slot="reference"
                      :src="require('@/assets/imgs/i5.png')"
                      alt=""
                    />
                  </el-popover>
                </div>
              </div>
            </li>
            <li class="user-nomore" v-if="userNomore && userArr.length > 0">
              加载中...
            </li>
            <li
              class="user-nomore"
              v-else-if="!userNomore && userArr.length > 0"
            >
              没有更多了...
            </li>
          </ul>
        </div>
        <no-data v-if="userArr.length == 0" />
      </div>
    </div>
    <div
      class="page-right"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <countPage
        padding="5px 10px"
        margin="0 0 10px 0"
        :content="`共计${total}条消息待查阅`"
        style="box-shadow: 0 1px 3px 0 #d8d9d9;"
      />
      <div
        class="right-box"
        id="groupDetail-item"
        v-infinite-scroll="getTelegramUsersSearch"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div
          class="item-box"
          :class="messageId == item.message_id ? 'select-message' : ''"
          v-for="(item, i) in list"
          :key="i"
          :id="'message' + item.message_id"
        >
          <img
            class="left-img"
            :src="
              item.user_photo
                ? 'data:image/png;base64,' + item.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div>
            <div class="top">
              <el-popover
                v-if="item.user_id"
                placement="bottom"
                width="400"
                trigger="hover"
              >
                <div class="el-popover-child">
                  <el-descriptions
                    :column="1"
                    :labelStyle="{
                      width: '60px',
                      textAlign: 'right',
                      color: '#060606'
                    }"
                  >
                    <el-descriptions-item label="用户名ID">
                      {{ item.user_id }}
                    </el-descriptions-item>
                    <el-descriptions-item label="用户名">
                      {{ item.user_username }}
                    </el-descriptions-item>
                    <el-descriptions-item label="姓">
                      {{ item.user_first_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="名">
                      {{ item.user_last_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="账号检索">
                      <span
                        class="retrieval-span"
                        @click="userDetailFun(item.user_id)"
                      >
                        检索
                      </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号">
                      <span
                        style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(2, item.user_id)"
                      >
                        查询
                      </span>
                      <span
                        v-if="userInfo.isYs == 0"
                        style="margin-left: 10px; padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item
                      label="归属地"
                      v-if="userInfo.isYs == 0"
                    >
                      <span
                        style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item> -->
                  </el-descriptions>
                  <div class="popover-btn-box">
                    <el-button size="small" @click="remarkFun(item)">
                      标记该消息
                    </el-button>
                    <el-button size="small" @click="remarkAccountFun(item)">
                      标记该账号
                    </el-button>
                    <el-button size="small" @click="shieldAccountFun(item)">
                      屏蔽该账号
                    </el-button>
                    <el-button size="small" @click="copyLink(item)">
                      复制消息链接
                    </el-button>
                  </div>
                </div>
                <span slot="reference">
                  <span
                    class="top-1"
                    v-if="item.user_first_name || item.user_last_name"
                  >
                    {{ item.user_first_name }}&nbsp; {{ item.user_last_name }}
                  </span>
                  <span v-else class="top-1">
                    {{ item.user_id }}
                  </span>
                </span>
              </el-popover>
              <span
                class="top-2"
                style="margin-right:10px"
                v-if="item.user_first_name || item.user_last_name"
              >
                ID：{{ item.user_id }}
              </span>
              <span class="top-2">{{ item.message_date }}</span>
            </div>
            <div class="content">
              <span v-html="item.message_text"></span>
              <!--转发信息  -->
              <span class="content-qun" v-if="item.message_fwd_from_id !== ''">
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  v-if="item.forward_user.userId"
                >
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="用户名ID">
                        {{
                          item.forward_user.userId
                            ? item.forward_user.userId
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="用户名">
                        {{
                          item.forward_user.username
                            ? item.forward_user.username
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="姓">
                        {{
                          item.forward_user.userFirstName
                            ? item.forward_user.userFirstName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="名">
                        {{
                          item.forward_user.userLastName
                            ? item.forward_user.userLastName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="账号检索">
                        <span
                          class="retrieval-span"
                          @click="userDetailFun(item.forward_user.userId)"
                        >
                          检索
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item label="手机号">
                        <span
                          style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(2, item.forward_user.userId)"
                        >
                          查询
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item
                        label="归属地"
                        v-if="userInfo.isYs == 0"
                      >
                        <span
                          style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(1, item.forward_user.userId)"
                        >
                          查询归属地
                        </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">
                    转自：{{ item.message_fwd_from_id }}
                  </span>
                </el-popover>
                <span v-else>转自：{{ item.message_fwd_from_id }}</span>
              </span>
              <!-- 群信息 -->
              <span class="content-qun" v-if="item.channel_title">
                <el-popover placement="bottom" width="300" trigger="hover">
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="群名">
                        {{ item.channel_title }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群号">
                        {{ item.channel_id }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群用户名">
                        {{ item.channel_username }}
                      </el-descriptions-item>

                      <el-descriptions-item label="群简介">
                        {{ item.channel_summary }}
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">来自群：{{ item.channel_title }}</span>
                </el-popover>
              </span>
            </div>
            <div
              class="content"
              v-if="item.message_audio_url && item.message_audio_url !== ''"
            >
              <audio controls>
                <source :src="item.message_audio_url" type="audio/ogg" />
                <source :src="item.message_audio_url" type="audio/mpeg" />
              </audio>
            </div>
            <div
              class="content"
              v-if="item.message_img_url && item.message_img_url !== ''"
            >
              <img :src="item.message_img_url" alt="" :onerror="noPic" />
            </div>
            <div
              class="content"
              v-if="item.message_video_url && item.message_video_url !== ''"
            >
              <video controls :src="item.message_video_url"></video>
            </div>

            <!-- <el-popover
              placement="bottom"
              trigger="hover"
              popper-class="search-groupDetail-popover"
            >
              <div class="btn-box">
                <el-button type="text" @click="remarkFun(item)">
                  标记该消息
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="remarkAccountFun(item)"
                >
                  标记该账号
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="shieldAccountFun(item)"
                >
                  屏蔽该账号
                </el-button>
              </div>

              <img
                class="group-more"
                slot="reference"
                :src="require('@/assets/imgs/i5.png')"
                alt=""
              />
            </el-popover> -->
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
      </div>
      <noData v-show="list.length == 0" />
      <div class="add-router">
        <img
          title="检索条件"
          :src="require('@/assets/imgs/i9.png')"
          alt=""
          @click="addRouter"
        />
      </div>
    </div>
    <!-- 弹框 -->
    <searchDialog
      ref="searcGrouphDialog"
      :dialogVisible="dialogVisible"
      :groupId="groupId"
      :loading="loading"
      @closeGrouDialog="closeGrouDialog"
      @retrieval="retrieval"
    />
    <!-- 归属地查询 -->
    <userDetail
      ref="userDetailDialog"
      :placeVisible="placeVisible"
      @closePlace="closePlace"
    />
    <!-- 打标记 -->
    <markDialog
      @markUpdate="markUpdate"
      ref="markDialog"
      :channelId="info.channelId"
    />
    <!-- 成员归属地 -->
    <placePage
      ref="placeDialog"
      :placeVisible="memberVisible"
      @closePlaceDialog="closePlaceDialog"
      :channelId="info.channelId"
      @userMessage="userMessage"
    />
    <!-- 导出图片数量 -->
    <imgDialog :channelId="info.channelId" ref="imgDialogRef" />
  </div>
</template>

<script>
import { message, messageLocation } from '@/api/retrieval'
import {
  groupDetail,
  userList,
  userList2,
  channelAdminListApi,
  telegramUpdateTaskApi
} from '@/api/group'
import { addTabs } from '@/assets/js/addTabs'
import { delTipChannel } from '@/api/mark/markGroup'
import { remarkMessage, userBlacklistApi } from '@/api/mark/markAccount'
import searchDialog from './Dialog.vue'
import markDialog from '@/views/detail/mark/Index'
import placePage from './DialogPlace.vue'
import imgDialog from './ImgNumber.vue'
import { scrollTopFun } from '@/plugins/logFunction'
export default {
  name: 'GroupDetail',
  components: {
    searchDialog,
    markDialog,
    placePage,
    imgDialog
  },
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/nopic.png') + '"',
      globalApi: process.env.VUE_APP_BASE_API,
      current: 1,
      limit: 50,
      total: 0,
      list: [],
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: false,
      groupId: '',
      info: {
        tipChannelList: []
      },
      searchForm: {},
      // 成员数据
      select: 1, //群发言热度；0全部，1：1个月，2半年，3一年,4管理员
      selectUserId: null, //选中的成员 id
      userArr: [],
      userCurrent: 1,
      userLimit: 50,
      userTotal: 0,
      userLoadig: true,
      userNomore: true,
      userScrollDisabled: false,
      // 弹框数据
      dialogVisible: false,
      // 归属地
      placeVisible: false,
      // 消息id
      messageId: null,
      // 滚动条
      leftScrollTop: 0,
      rightScrollTop: 0,
      // 归属地成员
      memberVisible: false
    }
  },
  computed: {
    tabRouterList() {
      return this.$store.state.settings.tabs
    },
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },

  beforeRouteLeave(to, from, next) {
    let path = this.$route.path
    if (
      this.$vnode.parent &&
      this.$vnode.parent.componentInstance &&
      this.$vnode.parent.componentInstance.cache
    ) {
      if (Array.isArray(this.tabRouterList) && this.tabRouterList.length > 0) {
        if (
          !this.tabRouterList.some(item => {
            return item.path == path
          })
        ) {
          // this.$destroy()
          var key =
            this.$vnode.key == null
              ? this.$vnode.componentOptions.Ctor.cid +
                (this.$vnode.componentOptions.tag
                  ? `::${this.$vnode.componentOptions.tag}`
                  : '')
              : this.$vnode.key

          for (let i in this.$vnode.parent.componentInstance.cache) {
            if (i == key) {
              delete this.$vnode.parent.componentInstance.cache[key]
            }
          }
          this.$vnode.parent.componentInstance.keys.forEach((item, i) => {
            if (item == key) {
              this.$vnode.parent.componentInstance.keys.splice(i, 1)
            }
          })
        }
      }
    }

    next()
  },
  created() {
    if (this.$route.params.groupId) {
      this.groupId = this.$route.params.groupId
    } else {
      this.$router.go(-1)
    }
    if (this.$route.query.messageId) {
      this.messageId = this.$route.query.messageId
      this.scrollDisabled = true
      this.getMessageLocation().then(() => {
        this.$nextTick(() => {
          this.scrollFun('message' + this.messageId, 'groupDetail-item')
        })
      })
    }
    //群组信息
    this.getgroupInfo()
    this.getUserNumber() //获取群成员数量
  },
  mounted() {},
  activated() {
    if (this.$route.query.messageId) {
      this.selectUserId = null
      if (this.messageId !== this.$route.query.messageId) {
        this.messageId = this.$route.query.messageId
        this.scrollDisabled = true
        this.getMessageLocation().then(() => {
          this.$nextTick(() => {
            this.scrollFun('message' + this.messageId, 'groupDetail-item')
          })
        })
      } else {
        // this.scrollFun('message' + this.messageId, 'groupDetail-item')
        scrollTopFun('groupDetail-item', 'set', this.rightScrollTop)
      }
    } else {
      if (this.messageId) {
        this.messageId = null
        this.selectUserId = null
        this.groupId = this.$route.params.groupId
        this.current = 1
        this.list = []
        this.scrollDisabled = true
        this.getTelegramUsersSearch()
      }
      if (this.$route.params.groupId !== this.groupId) {
        this.selectUserId = null
        this.groupId = this.$route.params.groupId
        this.current = 1
        this.list = []
        this.scrollDisabled = false
      }
      scrollTopFun('groupDetail-item', 'set', this.rightScrollTop)
    }
    this.getgroupInfo()
    scrollTopFun('t-scroll-groupUser', 'set', this.leftScrollTop)
  },
  deactivated() {
    this.leftScrollTop = scrollTopFun('t-scroll-groupUser', 'get')
    this.rightScrollTop = scrollTopFun('groupDetail-item', 'get')
    if (Array.isArray(this.tabRouterList) && this.tabRouterList.length > 0) {
      if (
        !this.tabRouterList.some(item => {
          return item.path == '/group/detail/' + this.groupId
        })
      ) {
        var key =
          this.$vnode.key == null
            ? this.$vnode.componentOptions.Ctor.cid +
              (this.$vnode.componentOptions.tag
                ? `::${this.$vnode.componentOptions.tag}`
                : '')
            : this.$vnode.key

        for (let i in this.$vnode.parent.componentInstance.cache) {
          if (i == key) {
            delete this.$vnode.parent.componentInstance.cache[key]
          }
        }
        this.$vnode.parent.componentInstance.keys.forEach((item, i) => {
          if (item == key) {
            this.$vnode.parent.componentInstance.keys.splice(i, 1)
          }
        })
      }
    }
  },
  methods: {
    // 获取群成员信息

    //
    getgroupInfo() {
      return new Promise((resolve, reject) => {
        let that = this
        groupDetail(this.groupId).then(data => {
          this.info = Object.assign({}, data)
          resolve()
          addTabs(
            that,
            data.channelTitle,
            data.channelId,
            '/group/detail/',
            this.messageId
          )
        })
      })
    },
    getUserNumber() {
      userList(this.userCurrent, this.userLimit, {
        channelId: this.groupId
      }).then(data => {
        this.userTotal = data.all_count
      })
    },
    // 用户数据
    getuserList() {
      this.userLoadig = true
      if (this.select == 0) {
        this.userScrollDisabled = true
        this.userNomore = true
        userList(this.userCurrent, this.userLimit, {
          channelId: this.groupId
        }).then(data => {
          // this.userTotal = data.all_count
          if (data.items.length > 0) {
            this.userArr = this.userArr.concat(data.items)
            this.userCurrent++
            this.userScrollDisabled = false
            if (data.items.length < this.userLimit) {
              this.userNomore = false
            }
          } else {
            this.userNomore = false
          }
          this.userLoadig = false
        })
      } else if (this.select == 4) {
        this.userScrollDisabled = true
        this.userNomore = false
        channelAdminListApi(this.groupId).then(data => {
          if (data) {
            this.userArr = data
            this.userLoadig = false
          }
        })
      } else {
        this.userScrollDisabled = true
        this.userNomore = false
        userList2(this.groupId, this.select).then(data => {
          this.userArr = data

          this.userLoadig = false
        })
      }
    },
    // 切换发言热度
    selectFun(data) {
      this.leftScrollTop = 0
      this.userCurrent = 1
      this.userArr = []

      this.getuserList()
    },
    // 聊天数据
    getTelegramUsersSearch() {
      this.loading = true
      this.scrollDisabled = true
      return new Promise((resolver, reject) => {
        message(this.current, this.limit, {
          fromChannelId: this.groupId,
          fromUserId: this.selectUserId,

          skey: this.searchForm.skey,
          excludeKey: this.searchForm.excludeKey,
          keyType: this.searchForm.keyType,
          isContainImage: this.searchForm.isContainImage,
          isContainVideo: this.searchForm.isContainVideo,
          isForward: this.searchForm.isForward,
          startDate: this.searchForm.startDate,
          endDate: this.searchForm.endDate
        }).then(data => {
          this.loading = false
          this.total = data.all_count
          if (data.items.length > 0) {
            this.list = this.list.concat(data.items)
            this.current++
            this.scrollDisabled = false
            if (data.items.length < this.limit) {
              this.dataLoading = false
            }
          } else {
            this.dataLoading = false
          }
          resolver()
        })
      })
    },
    // 定位的聊天数据
    getMessageLocation() {
      return new Promise((resolve, reject) => {
        let that = this
        this.current = 1
        this.list = []
        this.loading = true
        messageLocation(this.groupId, this.messageId).then(data => {
          this.list = data
          this.total = data.length
          this.loading = false
          this.dataLoading = false
          resolve()
        })
      })
    },
    userMessage(id) {
      this.selectUserId = id
      this.scrollDisabled = true
      this.current = 1
      this.list = []
      this.$refs.searcGrouphDialog.searchForm.fromUserId = this.selectUserId
      this.getTelegramUsersSearch()
    },
    closePlace() {
      this.placeVisible = false
    },
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    userDetailFun(id) {
      this.$router.push('/user/detail/' + id)
    },
    addRouter() {
      this.dialogVisible = true
    },
    // 关闭弹框
    closeGrouDialog() {
      this.dialogVisible = false
    },
    // 弹框返回的数据
    retrieval(obj) {
      this.scrollDisabled = true

      if (obj.fromUserId == '' || obj.fromUserId == null) {
        this.selectUserId = ''
      } else {
        this.selectUserId = obj.fromUserId
      }
      this.current = 1
      this.list = []
      this.searchForm = Object.assign({}, obj)
      this.getTelegramUsersSearch().then(() => {
        this.dialogVisible = false
      })
    },
    // 打标记
    markFun() {
      this.$refs.markDialog.dialogVisible = true
    },
    cloesTag(id) {
      this.$confirm('此操作将删除该标记, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTipChannel(this.info.channelId, id).then(res => {
            this.getgroupInfo()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 标记刷新
    markUpdate() {
      this.getgroupInfo()
    },
    // 标记该消息
    remarkFun(row) {
      remarkMessage({
        uid: row.user_id,
        channelId: row.channel_id,
        messageId: row.message_id,
        remarkType: 1
      }).then(data => {
        this.$message({
          type: 'success',
          message: '消息标记成功！'
        })
      })
    },
    // 标记该账号
    remarkAccountFun(row) {
      remarkMessage({
        uid: row.user_id,
        remarkType: 2
      }).then(data => {
        this.$message({
          type: 'success',
          message: '账号标记成功！'
        })
      })
    },
    // 屏蔽该账号
    shieldAccountFun(row) {
      this.$confirm('确认屏蔽, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userBlacklistApi({ tgid: row.user_id }).then(() => {
            this.$message({
              type: 'success',
              message: '屏蔽成功！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消。'
          })
        })
    },
    // 复制消息链接
    copyLink(row) {
      let data = row.channel_username
        ? row.channel_username
        : 'c/' + row.channel_id
      let link = `https://t.me/${data}/${row.message_id}`
      const input = document.createElement('input') // 创建一个隐藏input（重要！）
      input.value = link // 赋值
      document.body.appendChild(input)
      input.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy') // 执行浏览器复制命令
        this.$message({ message: '复制成功', type: 'success' })
      }
      document.body.removeChild(input)
    },
    //
    scrollFun(childDom, parentDom) {
      // 滚动
      let c_dom = document.getElementById(childDom)
      let p_dom = document.getElementById(parentDom)
      p_dom.scrollTop = c_dom.offsetTop - 100
    },
    // 导出
    exportFile(channelId) {
      this.$confirm(
        '导出时间可能过长，期间禁止刷新、关闭浏览器，若导出异常，请前往导出记录下载',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          let href =
            process.env.VUE_APP_BASE_API +
            `/telegramChannels/export/${channelId}`
          window.open(href, '_blank')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 导出群成员
    exportMember(channelId) {
      this.$confirm(
        '导出时间可能过长，期间禁止刷新、关闭浏览器，若导出异常，请前往导出记录下载',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          let href =
            process.env.VUE_APP_BASE_API +
            `/telegramChannels/exportMember/${channelId}`
          window.open(href, '_blank')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 归属地导出
    exportAddress(channelId) {
      this.$confirm(
        '导出时间可能过长，期间禁止刷新、关闭浏览器！此功能将消耗积分，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          let href =
            process.env.VUE_APP_BASE_API +
            `/telegramChannels/exportCity/${channelId}`
          window.open(href, '_blank')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 导出图片数量
    exportImg() {
      this.$refs.imgDialogRef.initFun()
    },

    // 归属地成员
    openPlace(channelId) {
      this.memberVisible = true
      this.$nextTick(() => {
        this.$refs.placeDialog.ruleForm.country = ''
        this.$refs.placeDialog.ruleForm.province = ''
        this.$refs.placeDialog.tableData = []
        this.$refs.placeDialog.queryPage(channelId)
      })
    },
    closePlaceDialog() {
      this.memberVisible = false
    },
    // 更新群成员
    updateMember() {
      this.$confirm(
        '群成员更新时长取决于群成员数量，群成员越多所需更新时间越长，最长大约4个小时更新完成，是否需要继续更新？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          telegramUpdateTaskApi({
            channelId: this.groupId
          }).then(data => {
            this.$message.success('已提交群成员更新任务，请稍后查看。')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.group-detail {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  display: flex;
  justify-content: space-between;

  .page-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px 5px;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .user-photo {
        width: calc(100% - 30px);
        align-self: center;
        display: flex;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 5px;
          align-self: center;
        }
        > div {
          width: calc(100% - 40px);
          align-self: center;
          margin-left: 10px;
          .name {
            font-size: 14px;
            font-weight: 400;
            color: #1a1a1b;
            line-height: 25px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .id {
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            line-height: 20px;
          }
        }
      }
      .more {
        align-self: center;
        img {
          cursor: pointer;
          width: 25px;
          height: auto;
        }
      }
    }
    .content {
      width: 100%;
      padding: 5px 5px;
      max-height: 180px;
      overflow: hidden;
      overflow-y: auto;
      > div {
        width: 100%;
        display: flex;
        margin: 10px 0;
        label {
          width: 60px;
          font-size: 14px;
          font-weight: 500;
          color: #1a1a1b;
        }
        > div {
          width: calc(100% - 60px);
          font-size: 13px;
          font-weight: 300;
          color: #1a1a1b;
          line-height: 20px;
          overflow: hidden;
          word-wrap: break-word;

          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          word-break: break-all;
        }
        .memo {
          height: 40px;
          position: relative;
          overflow: auto;
        }
        .mark-box {
          height: 50px;
          overflow: auto;
          position: relative;
        }
      }
    }
    .member {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 6px;
      position: relative;
      > span {
        font-size: 14px;
        font-weight: 400;
      }
      > span:nth-child(2) {
        color: #ff5400;
      }
      a {
        background: #5b6eb2;
        color: #fff;
        padding: 1px 5px 0;
        font-size: 12px;
        border-radius: 5px;

        display: inline-block;
        line-height: 25px;
        position: absolute;
        right: 4px;
        top: 7px;
        img {
          vertical-align: text-bottom;
        }
      }
      .update-member {
        font-size: 12px;
        color: #0084ff;
        margin-left: 5px;
        user-select: none;
        cursor: pointer;
        &:hover {
          color: #5490c9;
        }
      }
    }
    .select-box {
      margin: 10px 0;
    }
    .group-list {
      width: 100%;
      height: calc(100% - 315px);
      position: relative;
      .group-list-child {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          padding: 10px 5px;
          border-bottom: 1px solid #f2f4f8;

          .left {
            display: flex;
            align-self: center;
            > span {
              display: inline-block;
              width: 29px;
              height: 29px;
              align-self: center;
              .left-img {
                width: 29px;
                height: 29px;
                border-radius: 5px;
                align-self: center;
              }
            }

            > div {
              margin-left: 10px;
              align-self: center;
              div {
                font-size: 12px;
                font-weight: 400;
                color: #121111;
                line-height: 20px;
                max-width: 140px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }

          .right {
            align-self: center;
            display: flex;
            .right-1 {
              text-align: center;
              align-self: center;
              div {
                font-size: 12px;
                font-weight: 400;
                color: #696969;
                max-width: 70px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            .right-2 {
              align-self: center;
              display: none;
              .more-img {
                width: 25px;
                height: 25px;
                cursor: pointer;
              }
            }
          }
          &:hover {
            background: #d0dffd;
            .right-2 {
              display: block;
            }
          }
        }
        .select-user {
          background: #d0dffd;
        }
        .user-nomore {
          width: 100%;
          border-bottom: none;

          font-size: 12px;
          color: #4c4c4e;
          justify-content: center;
        }
      }
    }
  }
  .page-right {
    width: calc(100% - 270px);
    height: 100%;
    //
    border-radius: 5px;
    position: relative;
    .right-box {
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      background: #ffffff;
      box-shadow: 0 1px 3px 0 #d8d9d9;

      .item-box {
        width: 100%;
        overflow: hidden;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2f4f8;
        margin-bottom: 10px;
        padding: 13px 10px;
        position: relative;
        .group-more {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 25px;
          display: none;
          cursor: pointer;
        }
        &:hover {
          .group-more {
            display: block;
          }
        }
        .left-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        > div {
          width: calc(100% - 50px);
          margin-left: 10px;

          .top {
            white-space: nowrap;
            .top-1 {
              font-size: 13px;
              margin-right: 15px;
              font-weight: 400;
              color: #4c4c4e;
              line-height: 18px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            .top-2 {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
            }
          }
          .content {
            font-size: 14px;
            margin-top: 5px;
            font-weight: 300;
            color: #030303;
            line-height: 18px;
            .content-qun {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              margin-top: 5px;
              margin-left: 20px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            video {
              width: 500px;
              height: 300px;
            }
            img {
              width: 300px !important;
              height: auto !important;
            }
          }
        }
      }
      .select-message {
        background: #c5d7e9;
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #918d8d;
      font-size: 12px;
      padding-bottom: 10px;
    }
    .add-router {
      position: absolute;
      right: 10px;
      top: 0;
      img {
        width: 32px;
        cursor: pointer;
      }
    }
  }
}
.group-detail-popover-box {
  .more-item {
    text-align: left;
    margin: 10px 0;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #222222;
      &:hover {
        color: blue;
      }
    }
  }
}
.group-page-popover {
  width: 125px !important;
  min-width: 50px !important;
  padding: 5px;
  .user-item {
    user-select: none;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
      color: #0084ff;
    }
  }
}
.retrieval-span {
  user-select: none;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #0084ff;
  }
}
.search-groupDetail-popover {
  width: 100px !important;
  min-width: 50px !important;
  .btn-box {
    text-align: center;
  }
}
</style>
