<template>
  <el-dialog
    title="成员归属地"
    :visible.sync="placeVisible"
    width="65%"
    top="20px"
    :before-close="handleClose"
  >
    <!-- <div class="title">
      *目前部分可落查账号支持归属地显示，全部可落查账号归属地显示预计5月完成。
    </div> -->
    <div>
      <el-form inline>
        <el-form-item label="区域" prop="region">
          <el-select
            @change="changeCountry"
            size="small"
            v-model="ruleForm.country"
            placeholder="请选择区域"
            filterable
            clearable
          >
            <el-option
              v-for="(item, i) in countryList"
              :key="i"
              :label="item.country"
              :value="item.country"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省份" prop="region">
          <el-select
            size="small"
            v-model="ruleForm.province"
            placeholder="请选择省份"
            filterable
            clearable
            :disabled="cityType"
          >
            <el-option
              v-for="(item, i) in cityList"
              :key="i"
              :label="item.city"
              :value="item.city"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            :disabled="loading"
            @click="searchData"
          >
            搜索
          </el-button>
          <el-button size="small" @click="resetData" :disabled="loading">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      style="overflow:hidden"
      v-loading="loading"
      element-loading-text="数据检索中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(128, 121, 121, 0.8)"
    >
      <el-table
        :data="tableData"
        height="730"
        stripe
        style="width: 100%"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: ' 0' }"
        class="place-table"
        ref="tableRef"
        current-row-key="id"
      >
        <el-table-column label="头像" width="50" align="center">
          <template slot-scope="scope">
            <img
              style="width:25px;height:25px;object-fit: cover; "
              :src="
                scope.row.userPhoto
                  ? 'data:image/png;base64,' + scope.row.userPhoto
                  : require('@/assets/imgs/p.png')
              "
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="userUsername" label="用户名"></el-table-column>
        <el-table-column prop="userId" label="用户ID"></el-table-column>
        <el-table-column prop="hot" sortable label="热度" width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.hot" class="hot-box">
              <img :src="require('@/assets/imgs/fire.png')" alt="" />
              <span>{{ scope.row.hot }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="province" label="归属地" sortable width="280">
          <template slot-scope="scope">
            <span>{{ scope.row.country }}</span>
            <span v-if="scope.row.province" v-html="scope.row.province"></span>
            <span>{{ scope.row.city ? scope.row.city : '' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="userFirstName" label="姓"></el-table-column>
        <el-table-column prop="userLastName" label="名"></el-table-column>
        <el-table-column
          prop="province"
          label="操作"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="seachPhone(2, scope.row.userId)">
              查询手机号
            </el-button>
            <!-- <el-button
              type="text"
              style="color:#67c23a"
              @click="userDetailFun(scope.row.userId)"
            >
              用户信息
            </el-button>
            <el-button
              type="text"
              style="color:#e6a23c"
              @click="userMessage(scope.row.userId)"
            >
              用户发言
            </el-button> -->
            <el-button
              type="text"
              style="color:#67c23a"
              @click="nextFun(scope.row)"
            >
              用户信息
            </el-button>
          </template>
        </el-table-column>
        <el-table-column type="expand" width="20">
          <template>
            <div class="table-expand">
              <detailPage ref="detailPageRef" @userDetailFun="userDetailFun" />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="el-footer" style="text-align: center">
        <!-- layout="total, sizes, prev, pager, next, jumper" -->
        <!-- <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          layout=" prev,next"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination> -->
        <div>
          <el-button
            type="primary"
            size="mini"
            :disabled="page <= 1"
            @click="prevPageFun"
          >
            上一页
          </el-button>
          <el-button
            @click="nextPageFun"
            type="primary"
            size="mini"
            :disabled="noMore"
          >
            下一页
          </el-button>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <!--      <el-button @click="handleClose" size="small">关 闭</el-button>-->
    </span>
    <!-- 归属地查询 -->
    <userDetail
      ref="userDetailDialog"
      :placeVisible="dialogVisible"
      @closePlace="closePlace"
    />
  </el-dialog>
</template>

<script>
import { userCityApi } from '@/api/group'
import detailPage from './expand/UserDetail.vue'
import { countryNameList } from '@/assets/js/countryList.js'
import { cityNameList } from '@/assets/js/cityList.js'
export default {
  props: ['placeVisible', 'channelId'],
  components: {
    detailPage
  },
  data() {
    return {
      ruleForm: {
        country: '',
        province: ''
      },
      loading: false,
      page: 1,
      limit: 20,
      total: 0,
      tableData: [],
      noMore: false,
      //
      dialogVisible: false,
      userDetail: {},
      countryList: countryNameList,
      cityList: cityNameList,
      cityType: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  created() {
    // console.log(textFile)
  },
  methods: {
    handleClose() {
      this.$emit('closePlaceDialog')
    },
    searchData() {
      this.page = 1
      this.queryPage()
    },
    resetData() {
      this.page = 1
      this.ruleForm.country = ''
      this.ruleForm.province = ''
      this.queryPage()
    },
    changeCountry(data) {
      if (data !== '中国') {
        this.cityType = true
        this.ruleForm.province = ''
      } else {
        this.cityType = false
      }
    },
    queryPage() {
      this.loading = true
      let obj = Object.assign(
        {
          channelId: this.channelId
        },
        this.ruleForm
      )

      userCityApi(this.page, this.limit, obj)
        .then(res => {
          this.tableData = res.items
          // this.total = res.all_count
          if (res.items.length < this.limit) {
            this.noMore = true
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
    },
    //
    // handleSizeChange(val) {
    //   this.limit = val
    //   this.queryPage()
    // },
    // handleCurrentChange(val) {
    //   this.page = val
    //   this.queryPage()
    // },
    // 分页
    prevPageFun() {
      this.page = this.page - 1

      this.queryPage()
    },
    nextPageFun() {
      this.page = this.page + 1

      this.queryPage()
    },
    //
    seachPhone(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.dialogVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    closePlace() {
      this.dialogVisible = false
    },
    // 用户信息
    userDetailFun(id) {
      this.$emit('closePlaceDialog')
      this.$router.push('/user/detail/' + id)
    },
    userMessage(id) {
      this.$emit('closePlaceDialog')
      this.$emit('userMessage', id)
    },
    // table展开
    nextFun(row) {
      this.$refs.tableRef.toggleRowExpansion(row)
      this.$nextTick(() => {
        this.$refs.detailPageRef.userId = row.userId
        this.$refs.detailPageRef.selectChannelId = this.channelId
        this.$refs.detailPageRef.initFun()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 12px;
  padding: 0 0 10px 10px;
  color: #b5b4b2;
}
::v-deep .el-form-item {
  //   width: 40%;
  overflow: hidden;
  margin-bottom: 0;
}
::v-deep .el-dialog__body {
  padding: 0 10px !important;
}
.place-table {
  ::v-deep .el-button {
    padding: 0 !important;
  }
}
.hot-box {
  img {
    width: 18px;
    height: auto;
    vertical-align: top;
  }
  span {
    vertical-align: middle;
    margin-left: 5px;
  }
}
.table-expand {
  width: 100%;
  height: 45vh;
  overflow: hidden;
}
</style>
