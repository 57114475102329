var countryNameList = [
  {
    country: '中国'
  },
  {
    country: '中国澳门特区'
  },
  {
    country: '中国香港'
  },
  {
    country: '中国台湾地区'
  },
  {
    country: '菲律宾'
  },
  {
    country: '美国'
  },
  {
    country: '韩国'
  },
  {
    country: '俄罗斯'
  },
  {
    country: '南非'
  },
  {
    country: '马来西亚'
  },
  {
    country: '缅甸'
  },
  {
    country: '柬埔寨'
  },
  {
    country: '英国'
  },
  {
    country: '老挝'
  },
  {
    country: '泰国'
  },
  {
    country: '阿拉伯联合酋长国'
  },
  {
    country: '柬埔寨'
  },
  {
    country: '西班牙'
  },
  {
    country: '印度尼西亚'
  },
  {
    country: '新加坡'
  },
  {
    country: '越南'
  },
  {
    country: '土耳其'
  },
  {
    country: '乌干达'
  },
  {
    country: '法国'
  },
  {
    country: '德国'
  },
  {
    country: '日本'
  },
  {
    country: '爱尔兰'
  },
  {
    country: '加拿大'
  },
  {
    country: '加纳'
  },
  {
    country: '尼日利亚'
  },
  {
    country: '伊朗'
  },
  {
    country: '新西兰'
  },
  {
    country: '塞内加尔'
  },
  {
    country: '阿富汗'
  },
  {
    country: '乌克兰'
  },
  {
    country: '巴哈马'
  },
  {
    country: '荷兰'
  },
  {
    country: '孟加拉'
  },
  {
    country: '格鲁吉亚'
  },
  {
    country: '印度'
  },
  {
    country: '巴西'
  },
  {
    country: '阿尔及利亚'
  },
  {
    country: '伊拉克'
  },
  {
    country: '巴基斯坦'
  },
  {
    country: '立陶宛'
  },
  {
    country: '厄瓜多尔'
  },
  {
    country: '突尼斯'
  },
  {
    country: '埃塞俄比亚'
  },
  {
    country: '坦桑尼亚'
  },
  {
    country: '塞浦路斯'
  },
  {
    country: '哈萨克斯坦'
  },
  {
    country: '白俄罗斯'
  },
  {
    country: '阿曼'
  },
  {
    country: '沙特阿拉伯'
  },
  {
    country: '苏丹'
  },
  {
    country: '乌兹别克斯坦'
  },
  {
    country: '也门'
  },
  {
    country: '阿联酋'
  },
  {
    country: '叙利亚'
  },
  {
    country: '意大利'
  },
  {
    country: '瑞典'
  },
  {
    country: '瑞士'
  },
  {
    country: '巴拿马'
  },
  {
    country: '罗马尼亚'
  },
  {
    country: '澳大利亚'
  },
  {
    country: '塞舌尔群岛'
  },
  {
    country: '波兰'
  },
  {
    country: '以色列'
  },
  {
    country: '埃及'
  },
  {
    country: '肯尼亚'
  },
  {
    country: '塞尔维亚'
  },
  {
    country: '秘鲁'
  },
  {
    country: '委内瑞拉'
  },
  {
    country: '奥地利'
  },
  {
    country: '多米尼加共和国'
  },
  {
    country: '丹麦'
  },
  {
    country: '牙买加'
  },
  {
    country: '阿根廷'
  },
  {
    country: '苏里南'
  },
  {
    country: '土库曼斯坦'
  },
  {
    country: '文莱'
  },
  {
    country: '比利时'
  },
  {
    country: '卡塔尔'
  },
  {
    country: '斯洛文尼亚'
  },
  {
    country: '肯尼亚'
  },
  {
    country: '斯里兰卡'
  },
  {
    country: '科特迪瓦'
  },
  {
    country: '特立尼达和多巴哥'
  },
  {
    country: '爱沙尼亚'
  },
  {
    country: '匈牙利'
  },
  {
    country: '瓦努阿图'
  },
  {
    country: '象牙海岸'
  },
  {
    country: '智利'
  },
  {
    country: '吉尔吉克斯坦'
  },
  {
    country: '阿尔巴尼亚'
  },
  {
    country: '津巴布韦'
  },
  {
    country: '利比亚'
  },
  {
    country: '索马里'
  },
  {
    country: '摩尔多瓦'
  },
  {
    country: '孟加拉国'
  },
  {
    country: '墨西哥'
  },
  {
    country: '泽西岛'
  },
  {
    country: '海地'
  },
  {
    country: '巴勒斯坦'
  },
  {
    country: '哥伦比亚'
  },
  {
    country: '美属维京群岛'
  },
  {
    country: '多米尼加'
  },
  {
    country: '捷克共和国'
  },
  {
    country: '多哥'
  },
  {
    country: '安哥拉'
  },
  {
    country: '刚果共和国'
  },
  {
    country: '阿塞拜疆'
  },
  {
    country: '葡萄牙'
  },
  {
    country: '卢旺达'
  },
  {
    country: '拉脱维亚'
  },
  {
    country: '马耳他'
  },
  {
    country: '贝宁'
  },
  {
    country: '塔吉克斯坦'
  },
  {
    country: '保加利亚'
  },
  {
    country: '帕劳'
  },
  {
    country: '莫桑比克'
  },
  {
    country: '洪都拉斯'
  },
  {
    country: '约旦'
  },
  {
    country: '卢森堡'
  },
  {
    country: '哥斯达黎加'
  },
  {
    country: '吉尔吉斯斯坦'
  },
  {
    country: '布基纳法索'
  },
  {
    country: '尼泊尔'
  },
  {
    country: '芬兰'
  },
  {
    country: '塞拉利昂'
  },
  {
    country: '玻利维亚'
  },
  {
    country: '美属北马里亚纳群岛'
  },
  {
    country: '库拉索岛'
  },
  {
    country: '刚果民主共和国'
  },
  {
    country: '亚美尼亚'
  },
  {
    country: '克罗地亚'
  },
  {
    country: '古巴'
  },
  {
    country: '蒙古'
  },
  {
    country: '赞比亚'
  },
  {
    country: '乌拉圭'
  },
  {
    country: '圭亚那'
  },
  {
    country: '斯洛伐克'
  },
  {
    country: '冈比亚'
  },
  {
    country: '摩洛哥'
  },
  {
    country: '几内亚'
  },
  {
    country: '巴拉圭'
  },
  {
    country: '马达加斯加'
  },
  {
    country: '格陵兰'
  },
  {
    country: '巴布亚新几内亚'
  },
  {
    country: '萨尔瓦多'
  },
  {
    country: '赤道几内亚'
  },
  {
    country: '巴林'
  },
  {
    country: '希腊'
  },
  {
    country: '喀麦隆'
  },
  {
    country: '毛里求斯'
  },
  {
    country: '尼日尔'
  },
  {
    country: '毛里塔尼亚'
  },
  {
    country: '瓜德罗普'
  },
  {
    country: '加蓬'
  },
  {
    country: '科威特'
  },
  {
    country: '所罗门群岛'
  },
  {
    country: '布隆迪'
  },
  {
    country: '塞拉里昂'
  },
  {
    country: '黎巴嫩'
  },
  {
    country: '东帝汶'
  },
  {
    country: '波黑'
  },
  {
    country: '孟加拉国'
  },
  {
    country: '波多黎哥'
  },
  {
    country: '博茨瓦纳'
  },
  {
    country: '马尔代夫'
  },
  {
    country: '危地马拉'
  },
  {
    country: '马拉维'
  },
  {
    country: '捷克'
  },
  {
    country: '安提瓜和巴布达'
  },
  {
    country: '南苏丹'
  },
  {
    country: '刚果'
  },
  {
    country: '尼加拉瓜'
  },
  {
    country: '纳米比亚'
  },
  {
    country: '不丹'
  },
  {
    country: '马里'
  },
  {
    country: '斐济'
  },
  {
    country: '波斯尼亚和黑山共和国'
  },
  {
    country: '中非共和国'
  },
  {
    country: '波多黎各'
  },
  {
    country: '斯威士兰'
  },
  {
    country: '圣卢西亚'
  },
  {
    country: '莱索托'
  },
  {
    country: '佛得角'
  },
  {
    country: '基里巴斯'
  },
  {
    country: '阿鲁巴'
  },
  {
    country: '库克群岛'
  },
  {
    country: '布隆迪'
  },
  {
    country: '留尼汪'
  },
  {
    country: '巴巴多斯'
  },
  {
    country: '格林纳达'
  },
  {
    country: '利比里亚'
  },
  {
    country: '列支敦士登'
  },
  {
    country: '萨摩亚'
  },
  {
    country: '中非'
  },
  {
    country: '马其顿王国'
  },
  {
    country: '冰岛'
  },
  {
    country: '马约特岛'
  },
  {
    country: '法罗群岛'
  },
  {
    country: '法属圭亚那'
  },
  {
    country: '乌拉圭'
  },
  {
    country: '马提尼克'
  },
  {
    country: '直布罗陀'
  },
  {
    country: '新喀里多尼亚'
  },
  {
    country: '黑山'
  },
  {
    country: '马绍尔群岛'
  },
  {
    country: '留尼汪岛'
  },
  {
    country: '汤加'
  },
  {
    country: '塞舌尔'
  },
  {
    country: '几内亚比绍'
  },
  {
    country: '乍得'
  },
  {
    country: '英属维京群岛'
  },
  {
    country: '挪威'
  },
  {
    country: '开曼群岛'
  },
  {
    country: '圣基茨和尼维斯'
  },
  {
    country: '新克里多尼亚群岛'
  },
  {
    country: '科摩罗'
  },
  {
    country: '伯里兹'
  },
  {
    country: '北马其顿'
  },
  {
    country: '库拉索'
  },
  {
    country: '圣赫勒拿、阿森松和特里斯坦-达库尼亚'
  },
  {
    country: '吉布提'
  },
  {
    country: '北马其顿'
  },
  {
    country: '安道尔'
  },
  {
    country: '圣文森特和格林纳丁斯'
  }
]
export { countryNameList }
