import request from '@/plugins/axios'

// 分页
export function tipChannel(tipId) {
  return request({
    url: `/tipChannel/${tipId}`,
    method: 'get'
  })
}

//添加群标记
export function addTipChannel(data) {
  return request({
    url: `/tipChannel`,
    method: 'post',
    data
  })
}

//删除群标记
export function delTipChannel(channelId, tipId) {
  return request({
    url: `/tipChannel/${channelId}/${tipId}`,
    method: 'delete'
  })
}

// 系统标记
export function tipList() {
  return request({
    url: `/channelTips/tipList`
  })
}

// 系统标记下的群组
export function channelTips(tip) {
  return request({
    url: `/channelTips/${tip}`
  })
}
