<template>
  <el-dialog
    title="检索"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="handleClose"
    v-loading="loading"
    element-loading-text="数据检索中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    append-to-body
  >
    <div style="overflow:hidden">
      <el-form inline label-width="110px">
        <el-form-item label="关键词">
          <el-input
            size="small"
            class="input-bottom"
            placeholder="请输入关键词，用空格隔开"
            v-model="searchForm.skey"
            type="textarea"
            style="width:220px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="排除词">
          <el-input
            size="small"
            class="input-bottom"
            v-model="searchForm.excludeKey"
            placeholder="请输入排除词，用空格隔开"
            type="textarea"
            style="width:220px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="群组ID">
          <el-input
            size="small"
            class="input-bottom"
            v-model="searchForm.fromChannelId"
            placeholder="请输入群组ID"
            clearable
            style="width:220px"
          ></el-input>
        </el-form-item>
        <el-form-item label="匹配模式">
          <el-select
            v-model="searchForm.keyType"
            placeholder="匹配模式"
            size="small"
            class="input-bottom"
            style="width:220px"
            clearable
          >
            <el-option label="短语匹配" :value="0"></el-option>
            <el-option label="分词匹配" :value="1"></el-option>
            <el-option label="分词匹配(必须全包含)" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否包含图片">
          <el-select
            v-model="searchForm.isContainImage"
            placeholder="是否包含图片"
            size="small"
            class="input-bottom"
            style="width:220px"
            clearable
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="仅图片" :value="1"></el-option>
            <el-option label="不包含图片" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否包含视频">
          <el-select
            v-model="searchForm.isContainVideo"
            placeholder="是否包含视频"
            size="small"
            class="input-bottom"
            style="width:220px"
            clearable
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="仅视频" :value="1"></el-option>
            <el-option label="不包含视频" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否检索转发">
          <el-select
            v-model="searchForm.isForward"
            placeholder="是否检索转发"
            size="small"
            class="input-bottom"
            style="width:220px"
            clearable
          >
            <el-option label="全部" :value="null"></el-option>
            <el-option label="仅转发" :value="1"></el-option>
            <el-option label="不包含转发" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始日期">
          <el-date-picker
            v-model="searchForm.startDate"
            type="datetime"
            size="small"
            style="width:220px"
            class="input-bottom"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="开始日期"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="截至日期">
          <el-date-picker
            v-model="searchForm.endDate"
            type="datetime"
            size="small"
            style="width:220px"
            class="input-bottom"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="截至日期"
            clearable
          ></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="sure">
        检 索
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['dialogVisible', 'userId', 'loading'],
  data() {
    return {
      searchForm: {
        skey: '',
        excludeKey: '',
        fromChannelId: '',
        fromUserId: this.userId,
        keyType: '',
        isContainImage: '',
        isContainVideo: '',
        isForward: '',
        startDate: '',
        endDate: ''
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeGrouDialog')
    },
    sure() {
      this.$emit('messageAll', this.searchForm)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  //   width: 40%;
  overflow: hidden;
}
</style>
