<template>
  <el-dialog
    title="导出图片数量"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="handleClose"
  >
    <div>
      <el-slider
        show-input
        :min="100"
        :max="1000"
        v-model="numberData"
      ></el-slider>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="sure">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { exportImgApi } from '@/api/group.js'
export default {
  props: ['channelId'],
  data() {
    return {
      dialogVisible: false,
      numberData: 0
    }
  },
  methods: {
    initFun() {
      this.dialogVisible = true
      this.numberData = 100
    },
    handleClose() {
      this.dialogVisible = false
    },
    sure() {
      exportImgApi(this.channelId, this.numberData).then(() => {
        this.$message.success('任务已提交，请前往导出记录下载！')
        this.dialogVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  //   width: 40%;
  overflow: hidden;
}
</style>
