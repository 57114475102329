<template>
  <div class="user-detail-dialog">
    <div class="page-left">
      <div class="top">
        <div class="user-photo">
          <img
            :src="
              info.user_photo
                ? 'data:image/png;base64,' + info.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div>
            <div class="name">{{ info.username }}</div>
            <div class="id">ID:{{ info.userId ? info.userId : '' }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div>
          <label>姓：</label>
          <div>{{ info.userLastName }}</div>
        </div>
        <div>
          <label>名：</label>
          <div :title="info.userFirstName">
            {{ info.userFirstName }}
          </div>
        </div>
      </div>
      <div class="member">
        <span>所在群</span>
        (
        <span>{{ userArr.length }}</span>
        )
      </div>

      <div class="user-list" v-loading="userLoadig">
        <div class="user-list-child" id="t-scroll-userDetail-left">
          <ul>
            <li
              v-for="(item, i) in userArr"
              :key="i"
              :class="selectChannelId == item.channelId ? 'select-user' : ''"
              @click="userMessage(item)"
            >
              <div class="left">
                <span>
                  <img
                    class="left-img"
                    slot="reference"
                    :src="
                      item.channelPhoto
                        ? 'data:image/png;base64,' + item.channelPhoto
                        : require('@/assets/imgs/p1.png')
                    "
                    alt=""
                  />
                </span>
                <div>
                  <div :title="item.channelTitle">{{ item.channelTitle }}</div>
                  <div>{{ item.channelId }}</div>
                </div>
              </div>
            </li>

            <li class="user-nomore">
              没有更多了...
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="page-right"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <countPage
        padding="5px 10px"
        margin="0 0 10px 0"
        :content="`共计${total}条消息待查阅`"
      />
      <div
        class="right-box"
        id="t-scroll-userDetail-right"
        v-infinite-scroll="getTelegramUsersSearch"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="item-box" v-for="(item, i) in list" :key="i">
          <img
            class="left-img"
            :src="
              item.user_photo
                ? 'data:image/png;base64,' + item.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div>
            <div class="top">
              <span
                class="top-1"
                v-if="item.user_first_name || item.user_last_name"
              >
                {{ item.user_first_name }}&nbsp; {{ item.user_last_name }}
              </span>
              <span v-else class="top-1">
                {{ item.user_id }}
              </span>
              <span class="top-2">{{ item.message_date }}</span>
            </div>
            <div class="content">
              <span v-html="item.message_text"></span>
              <!--转发信息  -->
              <span class="content-qun" v-if="item.message_fwd_from_id !== ''">
                <!-- v-if="item.forward_user.userId" -->
                <span>转自：{{ item.message_fwd_from_id }}</span>
              </span>
              <!-- 群信息 -->
              <span class="content-qun" v-if="item.channel_title">
                <span slot="reference">来自群：{{ item.channel_title }}</span>
              </span>
            </div>
            <div class="content" v-if="item.message_audio_url !== ''">
              <audio controls>
                <source :src="item.message_audio_url" type="audio/ogg" />
                <source :src="item.message_audio_url" type="audio/mpeg" />
              </audio>
            </div>
            <div class="content" v-if="item.message_img_url !== ''">
              <img :src="item.message_img_url" alt="" />
            </div>
            <div class="content" v-if="item.message_video_url !== ''">
              <video controls :src="item.message_video_url"></video>
            </div>
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
        <!--  -->
      </div>
      <noData v-show="list.length == 0" />
      <el-button class="add-router" style="top:7px" type="text" @click="toPage">
        进入详情页
      </el-button>
    </div>
  </div>
</template>

<script>
import { message } from '@/api/retrieval'
import { userDetailApi, userInGroup } from '@/api/group'
import { addTabs } from '@/assets/js/addTabs'
import searchDialog from './Dialog.vue'
import { remarkMessage, userBlacklistApi } from '@/api/mark/markAccount'
export default {
  name: 'UserDetailPage',
  components: {
    searchDialog
  },

  data() {
    return {
      current: 1,
      limit: 50,
      total: 0,
      list: [],
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: false,
      userId: '',
      info: {},
      searchForm: {},

      selectChannelId: null, //选中的成员 id
      userArr: [],
      userCurrent: 1,
      userLimit: 50,
      userLoadig: true,
      leftScrollTop: 0,
      rightScrollTop: 0,

      // 弹框数据
      dialogVisible: false,
      // 归属地弹框
      placeVisible: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },

  created() {
    // this.getUserInfo() //用户信息
    // this.getUserNumber() //获取用户所在群
  },

  methods: {
    getUserInfo() {
      let that = this
      userDetailApi(this.userId)
        .then(data => {
          this.info = Object.assign({}, data)
        })
        .catch(() => {})
    },
    getUserNumber() {
      this.userLoadig = true
      userInGroup(this.userId)
        .then(data => {
          let arr1 = data.filter(item => {
            return item.channelId == this.selectChannelId
          })
          let arr2 = data.filter(item => {
            return item.channelId !== this.selectChannelId
          })
          this.userArr = arr1.concat(arr2)

          this.userLoadig = false
        })
        .catch(() => {
          this.userLoadig = false
        })
    },
    // 群检索
    retrieval(id) {
      this.$router.push('/group/detail/' + id)
    },
    // 定位到群组
    locationGroup(channel_id, message_id) {
      this.$router.push(
        '/group/detail/' + channel_id + '?messageId=' + message_id
      )
    },

    // 聊天数据
    getTelegramUsersSearch() {
      this.loading = true
      this.scrollDisabled = true
      return new Promise((resolver, reject) => {
        message(this.current, this.limit, {
          fromChannelId: this.selectChannelId,
          fromUserId: this.userId,

          skey: this.searchForm.skey,
          excludeKey: this.searchForm.excludeKey,
          keyType: this.searchForm.keyType,
          isContainImage: this.searchForm.isContainImage,
          isContainVideo: this.searchForm.isContainVideo,
          isForward: this.searchForm.isForward,
          startDate: this.searchForm.startDate,
          endDate: this.searchForm.endDate
        }).then(data => {
          this.loading = false
          this.total = data.all_count
          if (data.items.length > 0) {
            this.list = this.list.concat(data.items)
            this.current++
            this.scrollDisabled = false
            if (data.items.length < this.limit) {
              this.dataLoading = false
            }
          } else {
            this.dataLoading = false
          }
          resolver()
        })
      })
    },
    userMessage(row) {
      this.selectChannelId = row.channelId
      this.scrollDisabled = true
      this.current = 1
      this.list = []

      this.getTelegramUsersSearch()
    },
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    //
    closePlace() {
      this.placeVisible = false
    },
    addRouter() {
      this.dialogVisible = true
    },
    // 关闭条件查询弹框
    closeGrouDialog() {
      this.dialogVisible = false
    },
    // 弹框返回的数据
    messageAll(obj) {
      this.scrollDisabled = true

      if (obj.fromChannelId == '' || obj.fromChannelId == null) {
        this.selectChannelId = ''
      } else {
        this.selectChannelId = obj.fromChannelId
      }
      this.current = 1
      this.list = []
      this.searchForm = Object.assign({}, obj)
      this.getTelegramUsersSearch().then(() => {
        this.dialogVisible = false
      })
    },

    // 标记该账号
    remarkAccountFun(row) {
      remarkMessage({
        uid: this.info.userId,
        remarkType: 2
      }).then(data => {
        this.$message({
          type: 'success',
          message: '账号标记成功！'
        })
      })
    },
    // 屏蔽该账号
    shieldAccountFun(row) {
      this.$confirm('确认屏蔽, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userBlacklistApi({ tgid: row.user_id }).then(() => {
            this.$message({
              type: 'success',
              message: '屏蔽成功！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消。'
          })
        })
    },
    // 标记该消息
    remarkFun(row) {
      remarkMessage({
        uid: row.user_id,
        channelId: row.channel_id,
        messageId: row.message_id,
        remarkType: 1
      }).then(data => {
        this.$message({
          type: 'success',
          message: '消息标记成功！'
        })
      })
    },
    // 初始化
    initFun() {
      this.getUserInfo()
      this.getUserNumber()
    },
    toPage() {
      this.$emit('userDetailFun', this.userId)
    }
  }
}
</script>

<style lang="scss" scopde>
.user-detail-dialog {
  padding: 10px;
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  display: flex;
  justify-content: space-between;
  .page-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .user-photo {
        width: calc(100% - 30px);
        align-self: center;
        display: flex;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 5px;
          align-self: center;
        }
        > div {
          width: calc(100% - 40px);
          align-self: center;
          margin-left: 10px;
          .name {
            font-size: 14px;
            font-weight: 400;
            color: #1a1a1b;
            line-height: 25px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .id {
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            line-height: 20px;
          }
        }
      }
      .more {
        align-self: center;
        img {
          cursor: pointer;
          width: 25px;
          height: auto;
        }
      }
    }
    .content {
      width: 100%;
      padding: 5px 5px;
      > div {
        width: 100%;
        display: flex;
        margin: 10px 0;
        label {
          width: 45px;
          font-size: 14px;
          font-weight: 500;
          color: #1a1a1b;
        }
        > div {
          width: calc(100% - 50px);
          font-size: 13px;
          font-weight: 300;
          color: #1a1a1b;
          line-height: 20px;
          overflow: hidden;
          word-wrap: break-word;

          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          word-break: break-all;
          .query-sapn {
            text-decoration: underline;
            cursor: pointer;
            user-select: none;
            &:hover {
              color: #0084ff;
            }
          }
        }
      }
    }
    .member {
      height: 38px;
      background: #f2f4f8;
      line-height: 38px;
      padding-left: 10px;
      > span {
        font-size: 14px;
        font-weight: 400;
      }
      > span:nth-child(2) {
        color: #ff5400;
      }
    }
    .select-box {
      margin: 10px 0;
    }
    .user-list {
      width: 100%;
      height: calc(100% - 160px);

      .user-list-child {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          justify-content: space-between;
          padding: 10px 5px;
          border-bottom: 1px solid #f2f4f8;

          .left {
            display: flex;
            align-self: center;
            > span {
              display: inline-block;
              width: 29px;
              height: 29px;
              align-self: center;
              .left-img {
                width: 29px;
                height: 29px;
                border-radius: 5px;
                align-self: center;
              }
            }

            > div {
              margin-left: 10px;
              align-self: center;
              div {
                font-size: 12px;
                font-weight: 400;
                color: #121111;
                line-height: 20px;
                max-width: 140px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }

          .right {
            align-self: center;
            text-align: center;
            div {
              font-size: 12px;
              font-weight: 400;
              color: #696969;
              max-width: 70px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .right-img {
              width: 25px;
              height: auto;
              cursor: pointer;
              display: none;
            }
          }
          &:hover {
            background: #d0dffd;
            .right-img {
              display: block;
            }
          }
        }

        .select-user {
          background: #d0dffd;
        }
        .user-nomore {
          width: 100%;
          border-bottom: none;

          font-size: 12px;
          color: #4c4c4e;
          justify-content: center;
        }
      }
    }
  }
  .page-right {
    width: calc(100% - 270px);
    height: 100%;
    // background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .right-box {
      border-radius: 5px;
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      background: #ffffff;
      .item-box {
        width: 100%;
        overflow: hidden;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2f4f8;
        margin-bottom: 10px;
        padding: 13px 10px;
        padding-right: 20px;
        position: relative;
        .news-more {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          width: 25px;
          display: none;
          cursor: pointer;
        }
        &:hover {
          .news-more {
            display: block;
          }
        }
        .left-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        > div {
          width: calc(100% - 50px);
          margin-left: 10px;

          .top {
            white-space: nowrap;
            .top-1 {
              font-size: 13px;
              margin-right: 15px;
              font-weight: 400;
              color: #4c4c4e;
              line-height: 18px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            .top-2 {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
            }
          }
          .content {
            font-size: 14px;
            margin-top: 5px;
            font-weight: 300;
            color: #030303;
            line-height: 18px;
            .content-qun {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              margin-top: 5px;
              margin-left: 20px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            video {
              width: 500px;
              height: 300px;
            }
            img {
              width: 300px !important;
              height: auto !important;
            }
          }
        }
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
    .add-router {
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }
}
</style>
