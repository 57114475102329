let cityNameList = [
  {
    city: '山东'
  },
  {
    city: '广东'
  },
  {
    city: '安徽'
  },
  {
    city: '湖北'
  },
  {
    city: '山西'
  },
  {
    city: '重庆'
  },
  {
    city: '江西'
  },
  {
    city: '上海'
  },
  {
    city: '浙江'
  },
  {
    city: '北京'
  },
  {
    city: '辽宁'
  },
  {
    city: '江苏'
  },
  {
    city: '四川'
  },
  {
    city: '天津'
  },
  {
    city: '福建'
  },
  {
    city: '河北'
  },
  {
    city: '吉林'
  },
  {
    city: '广西'
  },
  {
    city: '陕西'
  },
  {
    city: '甘肃'
  },
  {
    city: '河南'
  },
  {
    city: '湖南'
  },
  {
    city: '贵州'
  },
  {
    city: '云南'
  },
  {
    city: '海南'
  },
  {
    city: '黑龙江'
  },
  {
    city: '内蒙古'
  },
  {
    city: '西藏'
  },
  {
    city: '新疆'
  },
  {
    city: '青海'
  },
  {
    city: '宁夏'
  }
]
export { cityNameList }
