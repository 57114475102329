import request from '@/plugins/axios'

// 分页
export function tipGroup(current, limit, data) {
  return request({
    url: `/tipGroup/${current}/${limit}`,
    method: 'post',
    data
  })
}

// 创建
export function addTipGroup(data) {
  return request({
    url: `/tipGroup`,
    method: 'post',
    data
  })
}

// 删除
export function delTipGroup(id) {
  return request({
    url: `/tipGroup/${id}`,
    method: 'delete'
  })
}

// 修改
export function editTipGroup(id, data) {
  return request({
    url: `/tipGroup/${id}`,
    method: 'put',
    data
  })
}
