<template>
  <el-dialog
    title="标记"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标记分类" prop="groupId">
          <div>
            <el-select
              filterable
              clearable
              v-model="ruleForm.groupId"
              placeholder="请选择标记分类"
              size="small"
              :style="{ width: 'calc(100% - 100px)' }"
              @change="changeGroup"
            >
              <el-option
                :label="item.groupName"
                :value="item.id"
                v-for="(item, i) in tipGroupList"
                :key="i"
              ></el-option>
            </el-select>
            <el-link type="primary" style="margin-left:10px" @click="addMark">
              添加标记分类
            </el-link>
          </div>
        </el-form-item>

        <el-form-item label="选择标记" prop="tipId">
          <el-select
            filterable
            clearable
            v-model="ruleForm.tipId"
            placeholder="请选择标记"
            size="small"
            style="width:100%"
            :disabled="!ruleForm.groupId"
          >
            <el-option
              :label="item.tipName"
              :value="item.id"
              v-for="(item, i) in markList"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="或输入标记" prop="tipName">
          <el-input
            size="small"
            style="width:100%"
            placeholder="输入标记"
            v-model="ruleForm.tipName"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      <el-button type="primary" size="small" @click="sureData">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { tipGroup } from '@/api/mark/menage'
import { addTipChannel } from '@/api/mark/markGroup'
export default {
  props: ['channelId'],
  data() {
    return {
      ruleForm: {
        groupId: null,
        tipId: null,
        tipName: null
      },
      rules: {
        groupId: [
          { required: true, message: '请选择标记分类', trigger: 'change' }
        ]
      },
      tipGroupList: [],
      markList: [],
      dialogVisible: false
    }
  },
  created() {
    this.gettipGroup()
  },
  methods: {
    addMark() {
      this.dialogVisible = false
      this.$router.push('/mark/management')
    },
    gettipGroup() {
      tipGroup(1, 1000).then(data => {
        this.tipGroupList = data.items
      })
    },
    changeGroup(data) {
      if (data) {
        this.tipGroupList.forEach(item => {
          if (data == item.id) {
            this.markList = [].concat(item.tipList)
          }
        })
      } else {
        this.ruleForm.tipId = null
      }
    },
    sureData() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          // if (
          //   this.ruleForm.tipId &&
          //   (this.ruleForm.tipName == null || this.ruleForm.tipName == '')
          // ) {
          //   this.$message({
          //     type: 'warning',
          //     message: '选择标记与输入标记二选一。'
          //   })
          //   return
          // }
          let obj = {
            channelId: this.channelId,
            groupId: this.ruleForm.groupId,
            tipId: this.ruleForm.tipId,
            tipName: this.ruleForm.tipName
          }
          if (obj.tipId) {
            delete obj.tipName
          } else if (obj.tipName && obj.tipName !== '') {
            delete obj.tipId
          }

          addTipChannel(obj).then(data => {
            this.$message({
              type: 'success',
              message: '标记成功！'
            })
            this.ruleForm.groupId = null
            this.ruleForm.tipId = null
            this.ruleForm.tipName = null
            this.dialogVisible = false
            this.$emit('markUpdate')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style></style>
